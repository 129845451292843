@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

@media print {
  @page {
    margin-left: 0.8in;
    margin-right: 0.8in;
    margin-top: 0;
    margin-bottom: 0;
  }
}

pdf {
  width: 100%;
  aspect-ratio: 4 / 3;
}

#pdf-content,
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
#pdf-content {
  @apply pb-5;
}

body {
  @apply relative m-0 overflow-x-hidden lg:bg-gray lg:overflow-hidden;
  font-family: 'Poppins', sans-serif;
}

*::-webkit-scrollbar {
  width: 3px;
}
*::-webkit-scrollbar-track {
  @apply bg-gray bg-opacity-20;
}
*::-webkit-scrollbar-thumb {
  @apply rounded-lg bg-gray bg-opacity-30;
}

.park_buyer-list::-webkit-scrollbar {
  width: 3px;
}
.park_buyer-list::-webkit-scrollbar-track {
  @apply bg-gray bg-opacity-20;
}
.park_buyer-list::-webkit-scrollbar-thumb {
  @apply rounded-md bg-gray bg-opacity-40;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}
p,
span,
i,
input,
select,
textarea,
a,
b,
strong,
button {
  font-family: 'Poppins', sans-serif;
}

/* Common CSS Start */
.flex-center {
  @apply flex items-center justify-center;
}
.custom-input {
  @apply w-full sm:h-[48px] h-[35px] rounded-md text-sm font-light border-[1px] border-gray border-opacity-10 bg-transparent px-3 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white flex-center;
}
.custom-radio {
  @apply flex-center text-sm border-[1px] border-gray border-opacity-10 cursor-pointer rounded-md px-2 py-3 min-w-[50px];
}

input:checked + .custom-radio {
  @apply border-primary/0;
}
.custom-half-input {
  @apply w-[50%] 
  sm:w-full 
  sm:h-[48px] 
  h-[35px] 
  rounded-md 
  text-sm 
  font-normal 
  border-[1px] 
  border-gray 
  border-opacity-10 
  bg-transparent 
  px-3 
  outline-none 
  transition 
  focus:border-primary 
  active:border-primary 
  disabled:cursor-default 
  disabled:bg-white 
  flex-center;
}
.custom-radio-new {
  @apply rounded-md 
  text-sm 
  font-normal 
  bg-primary/10 
  border-[1px] 
  border-primary 
  border-opacity-10 
  py-3 
  px-5 
  outline-none 
  transition 
  focus:border-primary 
  active:border-primary 
  disabled:cursor-default disabled:bg-white flex-center duration-300 delay-150 ease-linear;
}
.custom-radio-new:hover {
  @apply text-white border-primary bg-primary;
}
input:checked + .custom-radio-new {
  @apply text-white border-primary bg-primary;
}
/* Common CSS End */

/* btn Start */
.btn-custom {
  @apply relative flex flex-wrap gap-5;
}
.btn {
  @apply flex flex-wrap items-center justify-center px-5 py-3 text-sm font-normal rounded-md;
}
.btn-gray {
  @apply text-white border bg-gray hover:bg-transparent border-gray hover:text-gray;
}
.btn-primary {
  @apply text-white border bg-primary hover:bg-transparent border-primary hover:text-primary;
}
.btn-primary-border {
  @apply bg-transparent border hover:bg-transparent border-primary text-primary hover:text-white hover:bg-primary;
}
.btn-primary:hover .loader {
  @apply border-primary border-t-transparent;
}
.btn-primary-ico {
  @apply flex items-center justify-center gap-[0.2rem] bg-primary bg-opacity-10 hover:bg-opacity-100 text-primary hover:text-white;
}
.btn-primary-ico svg {
  @apply text-lg;
}
.btn-dark-ico {
  @apply flex items-center justify-center gap-2 bg-gray bg-opacity-10 hover:bg-opacity-100 text-gray hover:text-white;
}
.btn-dark-ico svg {
  @apply text-lg;
}
.btn-full {
  @apply w-full;
}

.btn-primary-link {
  @apply font-medium text-primary hover:underline;
}
.btn-gray-link {
  @apply font-medium text-gray hover:underline;
}

.btn-round {
  /* @apply flex flex-col items-center justify-center w-10 h-10 rounded-full; */
  @apply flex items-center justify-center w-8 h-8 p-0 transition rounded-full cursor-pointer bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white;
}

.btn-big-round {
  @apply flex items-center justify-center w-12 h-12 p-0 transition rounded-full cursor-pointer bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white;
}

.btm-menu-bar .card {
  @apply flex items-stretch justify-center border-0;
}

.btm-menu {
  @apply py-2 px-2 text-center text-[10px] font-medium text-white hover:bg-primary hover:text-white w-auto flex flex-wrap max-xs:gap-0 gap-1 items-center justify-center rounded-lg;
}
.btm-menu.active {
  @apply text-white bg-primary;
}

.sidebar_menu .group {
  @apply relative flex items-center gap-2.5 rounded-lg rounded-tl-[0] rounded-bl-[0] py-2 px-4 font-medium text-white duration-300 ease-in-out hover:bg-primary;
}
.sidebar_menu .search_mnu {
  @apply cursor-pointer relative flex items-center gap-2.5 rounded-lg rounded-tl-[0] rounded-bl-[0] py-2 px-4 font-medium text-white duration-300 ease-in-out hover:bg-primary;
}
/* btn End */

/* title Start */
.heading-1 {
  @apply text-2xl font-medium capitalize lg:text-4xl;
}
.heading-2 {
  @apply text-xl font-medium capitalize lg:text-3xl;
}
.heading-3 {
  @apply text-lg font-medium capitalize lg:text-2xl;
}
.heading-4 {
  @apply text-base font-medium capitalize lg:text-xl;
}
.heading-5 {
  @apply text-sm font-medium lg:text-lg;
}
.heading-6 {
  @apply text-sm font-medium capitalize;
}

.subHeading-2 {
  @apply text-2xl;
}
.subHeading-3 {
  @apply text-xl;
}
.subHeading-4 {
  @apply text-lg;
}
.subHeading-5 {
  @apply text-base;
}
.subHeading-6 {
  @apply text-xs;
}

.title-white {
  @apply text-white;
}
.title-gray {
  @apply text-gray;
}
.title-primary {
  @apply text-primary;
}
.title-violet {
  @apply text-violet;
}
.title-green {
  @apply text-green;
}
.title-blue {
  @apply text-blue;
}
.title-b-bottom {
  @apply border-0 border-b border-gray border-opacity-10;
}

.text-80 {
  @apply opacity-80;
}
.text-70 {
  @apply opacity-70;
}
.text-60 {
  @apply opacity-60;
}
.text-50 {
  @apply opacity-50;
}
.text-40 {
  @apply opacity-40;
}
.text-30 {
  @apply opacity-30;
}
.text-20 {
  @apply opacity-20;
}
.text-10 {
  @apply opacity-10;
}

.subH-70 .subHeading-2,
.subH-70 .subHeading-3,
.subH-70 .subHeading-4,
.subH-70 .subHeading-5,
.subH-70 .subHeading-6 {
  @apply opacity-70;
}
.subH-60 .subHeading-2,
.subH-60 .subHeading-3,
.subH-60 .subHeading-4,
.subH-60 .subHeading-5,
.subH-60 .subHeading-6 {
  @apply opacity-60;
}
.subH-50 .subHeading-2,
.subH-50 .subHeading-3,
.subH-50 .subHeading-4,
.subH-50 .subHeading-5,
.subH-50 .subHeading-6 {
  @apply opacity-50;
}
.subH-40 .subHeading-2,
.subH-40 .subHeading-3,
.subH-40 .subHeading-4,
.subH-40 .subHeading-5,
.subH-40 .subHeading-6 {
  @apply opacity-40;
}
.subH-30 .subHeading-2,
.subH-30 .subHeading-3,
.subH-30 .subHeading-4,
.subH-30 .subHeading-5,
.subH-30 .subHeading-6 {
  @apply opacity-30;
}
.subH-20 .subHeading-2,
.subH-20 .subHeading-3,
.subH-20 .subHeading-4,
.subH-20 .subHeading-5,
.subH-20 .subHeading-6 {
  @apply opacity-20;
}
.subH-10 .subHeading-2,
.subH-10 .subHeading-3,
.subH-10 .subHeading-4,
.subH-10 .subHeading-5,
.subH-10 .subHeading-6 {
  @apply opacity-10;
}

.content p {
  @apply mb-5 text-xs font-light md:text-sm text-gray text-opacity-70 last:mb-0;
}
.white p {
  @apply text-white;
}
.content p span {
  @apply font-medium text-primary;
}
.content p b,
.content p strong {
  @apply font-medium text-gray;
}
.content p a {
  @apply font-medium duration-300 ease-linear delay-150 text-primary hover:text-primary/70;
}

/* title End */

/* Home Page Swiper Start */
.swiper-pagination-clickable .swiper-pagination-bullet {
  margin-top: 20px !important;
  width: 12px !important;
  height: 12px !important;
  background-color: #e60a54 !important;
}
/* Home Page Swiper End */

/* Login & Signup Start */
.card-left {
  @apply bg-[url('assets/car/sliver-metallic-color-sport-car-driving-with-high-speed-road.jpg')] bg-center bg-cover bg-no-repeat h-auto lg:h-full text-center m-0 p-10 sm:p-20 flex flex-col justify-center items-center relative z-[1];
}
.card-left::after {
  content: '';
  @apply absolute top-0 left-0 w-full h-full bg-gray bg-opacity-70 mix-blend-multiply -z-[1];
}
.login-root {
  @apply flex items-center justify-center h-full lg:min-h-screen lg:h-screen; /*bg-[linear-gradient(to_right,_#e60a54_50%,_#3b3b3b_50%)]*/
}
.login-card-root {
  @apply flex flex-col items-center justify-start w-full p-5 py-8 m-0 bg-white lg:justify-center md:w-auto md:p-20;
}
.login-card-root .title {
  @apply mb-8;
}
.login-card-root .title h1 {
  @apply p-0 m-0 mb-2 text-3xl font-bold text-gray;
}
.login-card-root .title p {
  @apply w-full max-w-sm p-0 m-0 text-base font-normal text-opacity-75 text-gray;
}
.revvit-logo-wrapper {
  @apply flex flex-col items-center justify-center w-full font-medium text-gray;
}
.revvit-logo-wrapper img {
  @apply max-md:max-w-[60%] mx-auto;
}
.login-form-logo {
  @apply mb-0;
}
.form-title-section {
  @apply flex items-center justify-between w-full mt-4 font-normal text-primary;
}

.form-textarea {
  @apply w-full p-2 text-sm font-normal bg-transparent border rounded-md outline-none text-gray border-gray/10 focus:border-primary;
}

.form-field-wrapper {
  @apply flex flex-col gap-5;
}

.form-field {
  @apply w-full h-[46px] text-gray text-sm font-normal border border-gray/10 outline-none shadow-none rounded-md px-4 focus:border-primary bg-transparent;
}
.form-field::placeholder {
  @apply text-sm font-normal text-gray text-opacity-40;
}
.form-field-white {
  @apply w-full h-[46px] text-gray text-sm font-normal border border-gray/10 outline-none rounded-md px-4 focus:border-primary bg-white;
}

.form-field-white::placeholder {
  @apply text-sm font-normal text-gray text-opacity-40;
}

.login-card-root .form-field-wrapper h5:empty {
  @apply m-0;
}
.login-card-root .form-field {
  @apply w-full h-[46px] text-gray text-base font-medium border border-gray/10 outline-none rounded-md px-4 focus:border-primary;
}
.login-card-root .form-field::placeholder {
  @apply text-sm font-normal text-gray text-opacity-40;
}

.btn-wrapper {
  @apply flex items-center justify-between w-full h-full mt-8 text-primary;
}
.login-btn {
  @apply px-8 py-2 text-base font-normal text-white rounded-lg bg-primary md:px-12 md:py-3;
}
.forgot-password-text {
  @apply text-base font-normal cursor-pointer text-primary;
}
.divider-wraper {
  @apply flex items-center my-4 md:my-8;
}
.divider-line {
  @apply w-full border-primary;
}
.divider-content {
  @apply px-4 text-base font-medium text-primary;
}
.social-media-section {
  @apply flex flex-col items-center;
}
.social-media-title {
  @apply text-lg font-normal text-primary;
}
.social-media-icon-section {
  @apply flex items-center justify-center w-full gap-8 mt-4 md:mt-8;
}
.social-media-icon {
  @apply w-8 h-8 cursor-pointer;
}
/* Login & Signup end */

/* Select Roll Start */
.login-form-wraper {
  @apply p-5 my-5 text-center bg-white border sm:p-10 border-primary rounded-xl;
}
.form-logo-wraper {
  @apply text-2xl text-gray;
}
.form-logo {
  @apply w-10 h-auto m-auto mb-2 sm:w-14;
}
.form-title {
  @apply my-5 text-primary w-full flex justify-between text-sm sm:text-[15px];
}
/* Select Roll End */

/* grid Start */
.grid_2 {
  @apply relative flex flex-wrap items-start justify-start w-full p-0 m-0 gap-x-8 gap-y-10;
}
.grid_6 {
  @apply lg:w-auto w-full grid max-lg:grid-cols-3 max-large:grid-cols-6 large:grid-cols-6 lg:max-w-none gap-5 border-b border-gray border-opacity-10 place-items-center py-3 lg:py-5 last:mb-0 last:pb-0 last:border-b-0
        /* @apply flex flex-wrap items-center w-full gap-5 p-0 pb-5 m-0 mb-5 border-b border-gray border-opacity-10 lg:justify-between last:mb-0 last:pb-0 last:border-b-0; */;
}
.grid_5 {
  @apply grid w-full grid-cols-2 gap-5 py-3 border-b lg:w-auto max-lg:grid-cols-2 max-large:grid-cols-4 large:grid-cols-5 lg:max-w-none border-gray border-opacity-10 place-items-center lg:py-5 last:mb-0 last:pb-0 last:border-b-0;
}
.grid_7 {
  @apply grid w-full grid-cols-2 gap-5 py-3 border-b lg:w-auto md:grid-cols-2 lg:grid-cols-7 lg:max-w-none border-gray border-opacity-10 place-items-center lg:py-5 last:mb-0 last:pb-0 last:border-b-0;
}

.grid_info-6 {
  @apply grid w-full gap-5 p-0 py-5 m-0 mb-5 border-t border-b border-dashed max-lg:grid-cols-2 max-large:grid-cols-5 large:grid-cols-6 lg:max-w-none border-gray border-opacity-10;
}
.grid_info-5 {
  @apply grid w-full gap-5 p-0 py-5 m-0 mb-5 border-t border-b border-dashed max-lg:grid-cols-2 max-large:grid-cols-5 large:grid-cols-5 lg:max-w-none border-gray border-opacity-10;
}
/* grid End */

/* VIN Details Start */
.next-prev-button svg {
  @apply text-2xl;
}
.prev {
  @apply flex items-center w-auto bg-transparent border-none text-gray hover:bg-transparent hover:text-primary;
}
.prev svg {
  @apply text-3xl rotate-180;
}
/* VIN Details End */

/* Dashboard Header Start */
.header {
  @apply flex-wrap items-center justify-between hidden w-full lg:flex;
}
.header .title h1 {
  @apply p-0 m-0 text-base font-medium text-white capitalize lg:text-2xl;
}
.header ul {
  @apply flex flex-wrap items-center justify-end gap-4 p-0 m-0;
}
.header ul li a,
.header ul li div {
  @apply text-xl text-white cursor-pointer hover:text-primary;
}
/* Dashboard Header End */

/* identy Start */
.buyer,
.seller {
  @apply relative;
}
.buyer input,
.seller input {
  @apply absolute hidden;
}
.buyer span {
  @apply flex items-center justify-center text-2xl bg-white border border-dashed rounded cursor-pointer sm:text-3xl h-28 w-28 sm:w-48 sm:h-48 border-gray text-gray;
}
.buyer input:checked + span {
  @apply text-white bg-gray;
}

.seller span {
  @apply flex items-center justify-center text-2xl bg-white border border-dashed rounded cursor-pointer h-28 w-28 sm:w-48 sm:h-48 border-primary text-primary sm:text-3xl;
}
.seller input:checked + span {
  @apply text-white bg-primary;
}
.login-card-root.show .title {
  @apply hidden first:block;
}
.login-card-root.show .card-block {
  @apply hidden;
}
/* identy End */

/* seller-listing-view start */
.listing-vehicle .img.landscape {
  @apply relative overflow-hidden pt-[60%];
}

.listing-vehicle .landscape img {
  @apply absolute top-0 left-0 object-cover w-full h-full;
}
.details-view-single .listing-detail-detail ul {
  @apply flex flex-col items-start justify-start gap-3 p-0 m-0;
}
.details-view-single .listing-detail-detail ul li {
  @apply flex flex-wrap items-center w-full sm:gap-3;
}
.details-view-single .listing-detail-detail ul li .text {
  @apply flex flex-wrap items-center justify-start flex-1 gap-2 p-0 m-0 text-sm text-opacity-75 text-gray;
}
.details-view-single .listing-detail-detail ul li .value {
  @apply flex-1;
}
.tabs {
  @apply flex flex-wrap items-center gap-4;
}
.tabs.active {
  @apply text-primary;
}
.add-tabs {
  @apply max-sm:mb-5 max-sm:gap-3 max-sm:w-full max-sm:overflow-x-auto;
}
.add-tabs::-webkit-scrollbar {
  width: 100%;
  height: 3px;
}
.add-tabs::-webkit-scrollbar-track {
  @apply bg-gray bg-opacity-20;
}
.add-tabs::-webkit-scrollbar-thumb {
  @apply rounded-lg bg-gray bg-opacity-30;
}

.add-tabs .tabs {
  @apply max-sm:pb-3 max-sm:flex-[0_0_125px]  justify-center max-sm:cursor-grab;
}
.add-tabs .tabs.active {
  @apply text-white border-0 sm:rounded-tr-lg sm:rounded-tl-lg sm:bg-primary max-sm:text-primary max-sm:border-b max-sm:border-primary;
}
.tabs.active span {
  @apply text-white bg-primary border-primary;
}

.live-car_list .img-grid {
  @apply sm:grid-cols-2;
}
/* seller-listing-view end */

/* modal start */
.modal-content {
  @apply flex flex-col items-center justify-center gap-8 p-5 m-0 sm:p-8;
}
.alert-ico {
  @apply text-primary text-[54px];
}
/* modal End */

/* Upload file Start */
.uploaded-file {
  @apply relative flex flex-wrap items-start justify-start w-full gap-5 p-0 m-0;
}
.uploaded-file li {
  @apply rounded-lg bg-primary bg-opacity-5 cursor-pointer border-[1px] border-dashed border-primary border-opacity-20 relative overflow-hidden w-[200px] h-[200px];
}
/* Upload file End */

/* searchList Start */
.no-scrollbar nav::-webkit-scrollbar {
  width: 3px;
}
.no-scrollbar nav::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}
.no-scrollbar nav::-webkit-scrollbar-thumb {
  @apply bg-white bg-opacity-40;
}
.searchList > p {
  @apply p-0 px-5 m-0 text-sm font-normal text-gray first:mb-1;
}
.searchList .list {
  @apply relative flex flex-wrap w-full;
}
.searchList .list .innerlist {
  @apply relative flex flex-wrap items-center justify-between w-full gap-2 px-4 py-4 bg-transparent hover:bg-white;
}
.searchList .list .action-buttons {
  @apply flex gap-2;
}

.searchList .list .noResentActivity {
  @apply w-full px-5 py-2 my-2 text-center bg-white;
}
.searchList .list p {
  @apply p-0 m-0 text-sm font-normal text-gray last:font-extralight;
}
/* searchList End */

/* List view Start */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.items_list .landscape {
  @apply relative overflow-hidden pt-[55%];
}
.items_list .landscape img {
  @apply absolute top-0 left-0 object-cover w-full h-full;
}
.items_list .title-gray h2 {
  @apply p-0 m-0 mb-1 text-lg not-italic font-medium capitalize break-words text-gray md:text-2xl lg:text-3xl;
}
.items_list .title-gray h6 {
  @apply p-0 m-0 overflow-hidden text-sm not-italic font-normal normal-case text-gray text-ellipsis whitespace-nowrap;
}
.list-unstyled {
  @apply relative flex flex-wrap items-center justify-start gap-5 p-0 m-0;
}
.list-unstyled li {
  @apply relative flex flex-wrap items-start justify-start flex-auto gap-3 p-0 m-0;
}
.list-unstyled li svg {
  @apply mt-[4px] text-primary;
}
.list-unstyled li p {
  @apply flex flex-col items-start justify-start p-0 m-0 text-xs font-normal capitalize text-gray text-opacity-60;
}
.list-unstyled li p span {
  @apply text-sm font-medium text-opacity-100 text-gray;
}

.list-category {
  @apply relative flex flex-wrap items-center justify-start gap-3 p-0 m-0;
}
.list-category li {
  @apply block text-sm text-gray border-[1px] border-solid border-gray border-opacity-10 rounded-full py-2 px-5 bg-transparent hover:bg-primary hover:text-white cursor-default;
}

.favourite {
  @apply relative flex-wrap items-center justify-end p-0 m-0 text-base font-medium text-gray;
}
.favourite button {
  @apply flex flex-wrap items-center justify-center w-8 h-8 bg-transparent border-[1px] border-solid border-gray border-opacity-10 rounded-full m-0 ml-4 p-0 hover:bg-primary;
}
.favourite button:hover svg path {
  @apply fill-white;
}

.favourite-mob {
  @apply absolute flex-wrap items-center justify-end w-auto p-0 m-0 text-base font-medium text-gray;
}
.favourite-mob button {
  @apply flex flex-wrap items-center justify-center w-8 h-8 bg-white border-[1px] border-solid border-white border-opacity-10 rounded-full m-0 p-0 hover:bg-primary;
}
.favourite-mob button:hover svg path {
  @apply fill-white;
}

.price {
  @apply xl:w-full;
}
.price h2 {
  @apply p-0 m-0 text-2xl not-italic font-medium capitalize text-gray lg:text-3xl;
}
.price p {
  @apply p-0 m-0 text-sm not-italic font-normal normal-case text-gray;
}

.btn-filter {
  @apply hover:bg-primary hover:text-white;
}

.filter button {
  @apply w-full flex flex-wrap items-center justify-center gap-2 m-0 p-0 py-2 px-5 border-[1px] border-solid border-gray border-opacity-20 rounded-[4px] relative capitalize text-sm text-gray text-opacity-40 font-normal;
}
.advance-search-wrapper {
  @apply absolute top-[100%] left-0 w-full z-[1] mt-3;
}
/* List view End */

/* Details page Start */
.listing-detail-title h2 {
  @apply p-0 m-0 text-2xl not-italic font-medium capitalize text-gray lg:text-2xl;
}
.listing-tagline {
  @apply p-0 m-0 text-sm not-italic font-normal normal-case text-gray;
}
.detail_listing_signlefield {
  @apply flex flex-wrap items-center justify-start w-full gap-4 p-0 m-0 mt-4 sm:mt-8;
}
.detail_listing_signlefield li {
  @apply flex flex-wrap items-center justify-center gap-2 p-0 px-5 py-2 m-0 rounded-full bg-primary bg-opacity-20;
}
.detail_listing_signlefield li {
  @apply text-sm text-primary;
}
.detail_listing_signlefield li svg {
  @apply text-lg;
}

.details-single .avatar-slide .main {
  @apply mb-3;
}
.details-single .avatar-slide .main {
  @apply relative rounded-xl;
}
.details-single .avatar-slide .main .landscape {
  @apply relative pt-[60%] overflow-hidden w-full block;
}
.details-single .avatar-slide .main .landscape img {
  @apply absolute top-0 left-0 object-cover w-full h-full;
}

.details-single .avatar-slide .main .swiper-button-prev,
.details-single .avatar-slide .main .swiper-button-next {
  @apply flex flex-wrap items-center justify-center w-[44px] bg-gray bg-opacity-40 hover:bg-opacity-100 hover:bg-primary rounded-full;
}
.details-single .avatar-slide .main .swiper-button-prev:after,
.details-single .avatar-slide .main .swiper-button-next:after {
  @apply text-xl;
}

.details-single .avatar-slide .thumb .swiper-slide {
  @apply relative overflow-hidden rounded-xl;
}
.details-single .avatar-slide .thumb .landscape {
  @apply relative pt-[67.63%] overflow-hidden;
}
.details-single .avatar-slide .thumb .landscape img {
  @apply absolute top-0 left-0 object-cover w-full h-full;
}

.share {
  @apply relative flex flex-wrap items-center justify-end w-auto p-0 m-0 text-base font-medium text-gray;
}
.share button {
  @apply flex flex-wrap items-center justify-center w-8 h-8 bg-transparent border-[1px] border-solid border-gray border-opacity-10 rounded-full m-0 ml-2 p-0 hover:bg-primary hover:border-primary;
}
.share button:hover svg {
  @apply stroke-white;
}

.e-con-innre {
  @apply relative flex flex-col items-start justify-start w-full gap-5;
}
.e-con-innre > .listing-price {
  @apply block w-full pb-3 border-b border-gray border-opacity-10;
}
.listing-price h6 {
  @apply p-0 m-0 text-xl not-italic font-medium capitalize text-gray;
}
.listing-detail-price {
  @apply relative w-full p-0 m-0;
}
.listing-detail-price .listing-price {
  @apply relative w-auto p-0 m-0;
}
.listing-detail-price .listing-price .main-price p {
  @apply text-gray text-opacity-70;
}
.listing-detail-price .listing-price .old-price {
  @apply relative;
}
.listing-detail-price .listing-price .old-price:before {
  content: '';
  @apply absolute top-[50%] left-0 w-full h-[1px] translate-y-[-50%] bg-gray;
}
.listing-detail-price .listing-price .old-price .price-text {
  @apply p-0 m-0 text-base not-italic font-normal capitalize text-gray;
}
.listing-detail-price .listing-price .main-price .price-text {
  @apply p-0 m-0 text-xl not-italic font-medium capitalize text-gray lg:text-2xl;
}
.listing_bid {
  @apply flex flex-col items-start justify-start m-0 mb-5 p-0 pb-5 w-full border-0 border-b-[1px] border-solid border-gray border-opacity-10;
}
.listing_bid h6 {
  @apply p-0 m-0 text-sm not-italic font-normal capitalize text-gray text-opacity-70;
}
.listing_bid h4 {
  @apply p-0 m-0 text-xl not-italic font-medium capitalize text-gray;
}
.listing_bid_int {
  @apply relative block flex-auto p-0 m-0;
}
.listing_bid_int label {
  @apply block p-0 m-0 mb-1 text-sm not-italic font-normal capitalize text-gray text-opacity-70;
}
.listing_bid_int input {
  @apply block bg-white border-[1px] border-gray border-opacity-10 focus:border-primary border-solid rounded-[8px]  m-0 py-3 px-6 w-full font-normal not-italic text-gray text-sm outline-none shadow-none;
}

.listing_bid_int .dolor_sign {
  @apply absolute top-[23px] translate-y-[-50%] left-0 flex items-center px-3 text-lg text-gray text-opacity-40;
}

.listing_bid_int .btn-bid {
  @apply flex flex-wrap-reverse sm:flex-wrap m-0 mt-5 p-0 items-center justify-between w-full border-[1px] border-solid border-primary rounded-xl;
}
.listing_bid_int .btn-bid .btn-primary {
  @apply border-0 bg-primary text-white w-full sm:w-[50%] large:w-full xl:w-[50%] min-w-0 px-7 hover:bg-opacity-10 hover:text-primary;
}
.listing_bid_int .btn-bid span {
  @apply font-normal not-italic text-primary text-base m-0 p-0 normal-case w-full sm:w-[50%] large:w-full xl:w-[50%] text-center sm:py-0 large:py-3 xl:py-0 py-3;
}
.bid_detail p {
  @apply p-0 m-0 mt-5 text-sm not-italic font-normal normal-case text-gray text-opacity-70;
}

.user-content-wrapper .user-thumbnail {
  @apply w-[80px] h-[80px] overflow-hidden p-[5px] rounded-full border-[1px] border-solid border-gray border-opacity-10;
}
.user-content-wrapper .user-thumbnail img {
  @apply object-cover w-full h-full rounded-full;
}
.user-content {
  @apply mt-3;
}
.user-content .title-user {
  @apply p-0 m-0 text-base not-italic font-normal capitalize text-gray;
}
.user-content .author-address {
  @apply p-0 m-0 text-base not-italic font-normal text-gray text-opacity-60;
}
.user-contact {
  @apply relative flex flex-wrap items-center justify-start w-full gap-4 p-0 m-0 mt-3;
}
.user-contact span {
  @apply w-[40px] h-[40px] flex flex-wrap items-center justify-center relative bg-primary bg-opacity-20 rounded-full text-primary;
}
.user-contact a {
  @apply p-0 m-0 text-base not-italic font-normal text-gray hover:text-primary;
}
.user-content-bottom {
  @apply relative block mt-8;
}
.details_overview {
  @apply border-0 border-b-[1px] border-solid border-gray border-opacity-20 pb-10 last:mb-0 last:pb-0 last:border-b-0;
}
.details_overview .listing-detail-detail ul {
  @apply flex flex-col items-start justify-start w-full gap-5 p-0 m-0;
}
.details_overview .listing-detail-detail ul li {
  @apply relative flex flex-wrap items-center justify-start w-full gap-4 p-0 m-0 sm:flex-row sm:gap-3;
}
.details_overview .listing-detail-detail ul li > * {
  @apply flex-1;
}
.details_overview .listing-detail-detail ul li .value {
  @apply flex-1;
}
.details_overview .listing-detail-detail ul li .text,
.details_overview .listing-detail-detail ul li .value {
  @apply relative flex items-center justify-start w-auto gap-2 p-0 m-0 text-sm not-italic font-normal capitalize sm:flex-wrap text-gray;
}
.details_overview .listing-detail-detail ul li .text {
  @apply flex-row;
}

.details_overview .listing-detail-detail ul li .value {
  @apply capitalize;
}

.details_overview .listing-detail-detail ul li .text svg {
  @apply text-lg;
}

.details_overview .feature_list .title {
  @apply block mb-5;
}
.details_overview .feature_list .title h5 {
  @apply p-0 m-0 text-lg not-italic font-medium capitalize text-gray;
}
.details_overview .feature_list ul {
  @apply flex flex-col items-start justify-start w-full gap-5 p-0 m-0;
}
.details_overview .feature_list ul li {
  @apply flex flex-row items-center justify-start w-full gap-3 p-0 m-0 text-sm font-normal capitalize text-gray;
}
.details_overview .feature_list ul li span {
  @apply w-[18px] h-[18px] bg-primary bg-opacity-20 rounded-full text-primary flex flex-wrap items-center justify-center;
}

/* Details page End */
.fancybox__container {
  @apply z-[999999];
}
.grid-img_block.img {
  @apply first:col-span-2 first:row-span-2 relative overflow-hidden block w-full max-md:pt-[calc(4/5*100%)] md:pt-[calc(9/16*100%)];
}
.grid-img_block.img img {
  @apply absolute top-0 left-0 object-cover w-full h-full;
}
.grid-img_block.img:first-child img {
  @apply object-cover w-full h-full;
}
.chatbox {
  @apply bg-[#fafafa];
}
.chatbox::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.chatbox::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

.chatbox::-webkit-scrollbar-thumb {
  @apply bg-gray/50;
  border: 0px solid #555555;
}

.vehicle-filter {
  background-image: url('https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) center;
  background-size: 12px;
}
.color-snippet p[aria-label] {
  @apply w-7 h-7 rounded-full bg-black/10 block p-[2px] overflow-hidden relative border border-gray/10;
}

.color-snippet p[aria-label='#Black'] span {
  @apply block w-full h-full bg-black rounded-full;
}

.color-snippet.content p[aria-label='White'] span {
  @apply block w-full h-full bg-white rounded-full;
}

.color-snippet.content p[aria-label='Silver'] span {
  @apply w-full h-full bg-[#C0C0C0] block rounded-full;
}

.color-snippet.content p[aria-label='Gray'] span {
  @apply w-full h-full bg-[#808080] block rounded-full;
}

.color-snippet.content p[aria-label='Blue'] span {
  @apply w-full h-full bg-[#0000FF] block rounded-full;
}

.color-snippet.content p[aria-label='Red'] span {
  @apply w-full h-full bg-[#FF0000] block rounded-full;
}

.color-snippet.content p[aria-label='Yellow'] span {
  @apply w-full h-full bg-[#FFFF00] block rounded-full;
}

.color-snippet.content p[aria-label='Green'] span {
  @apply w-full h-full bg-[#008000] block rounded-full;
}

.color-snippet.content p[aria-label='Purple'] span {
  @apply w-full h-full bg-[#800080] block rounded-full;
}

.color-snippet.content p[aria-label='Orange'] span {
  @apply w-full h-full bg-[#FFA500] block rounded-full;
}

.color-snippet.content p[aria-label='Beige'] span {
  @apply w-full h-full bg-[#F5F5DC] block rounded-full;
}

.color-snippet.content p[aria-label='Brown'] span {
  @apply w-full h-full bg-[#A52A2A] block rounded-full;
}

.color-snippet.content p[aria-label='Bronze'] span {
  @apply w-full h-full bg-[#CD7F32] block rounded-full;
}

.color-snippet.content p[aria-label='Gold'] span {
  @apply w-full h-full bg-[#FFD700] block rounded-full;
}

.color-snippet.content p[aria-label='Pink'] span {
  @apply w-full h-full bg-[#FFC0CB] block rounded-full;
}

.color-snippet.content p[aria-label='Copper'] span {
  @apply w-full h-full bg-[#B87333] block rounded-full;
}

.color-snippet.content p[aria-label='Magenta'] span {
  @apply w-full h-full bg-[#FF00FF] block rounded-full;
}

.color-snippet.content p[aria-label='Turquoise'] span {
  @apply w-full h-full bg-[#40E0D0] block rounded-full;
}

.color-snippet.content p[aria-label='Lime'] span {
  @apply w-full h-full bg-[#00FF00] block rounded-full;
}

.value > span[aria-label],
.list-unstyled span[aria-label] {
  @apply w-7 h-7 rounded-full bg-black/10 block p-[2px] overflow-hidden border border-gray/10;
}

.value span[aria-label='Black'] span,
.list-unstyled span[aria-label='Black'] span {
  @apply block w-full h-full bg-black rounded-full;
}

.value span[aria-label='White'] span,
.list-unstyled span[aria-label='White'] span {
  @apply block w-full h-full bg-white rounded-full;
}

.value span[aria-label='Silver'] span,
.list-unstyled span[aria-label='Silver'] span {
  @apply w-full h-full bg-[#C0C0C0] block rounded-full;
}

.value span[aria-label='Gray'] span,
.list-unstyled span[aria-label='Gray'] span {
  @apply w-full h-full bg-[#808080] block rounded-full;
}

.value span[aria-label='Blue'] span,
.list-unstyled span[aria-label='Blue'] span {
  @apply w-full h-full bg-[#0000FF] block rounded-full;
}

.value span[aria-label='Red'] span,
.list-unstyled span[aria-label='Red'] span {
  @apply w-full h-full bg-[#FF0000] block rounded-full;
}

.value span[aria-label='Yellow'] span,
.list-unstyled span[aria-label='Yellow'] span {
  @apply w-full h-full bg-[#FFFF00] block rounded-full;
}

.value span[aria-label='Green'] span,
.list-unstyled span[aria-label='Green'] span {
  @apply w-full h-full bg-[#008000] block rounded-full;
}

.value span[aria-label='Purple'] span,
.list-unstyled span[aria-label='Purple'] span {
  @apply w-full h-full bg-[#800080] block rounded-full;
}

.value span[aria-label='Orange'] span,
.list-unstyled span[aria-label='Orange'] span {
  @apply w-full h-full bg-[#FFA500] block rounded-full;
}

.value span[aria-label='Beige'] span,
.list-unstyled span[aria-label='Beige'] span {
  @apply w-full h-full bg-[#F5F5DC] block rounded-full;
}

.value span[aria-label='Brown'] span,
.list-unstyled span[aria-label='Brown'] span {
  @apply w-full h-full bg-[#A52A2A] block rounded-full;
}

.value span[aria-label='Bronze'] span,
.list-unstyled span[aria-label='Bronze'] span {
  @apply w-full h-full bg-[#CD7F32] block rounded-full;
}

.value span[aria-label='Gold'] span,
.list-unstyled span[aria-label='Gold'] span {
  @apply w-full h-full bg-[#FFD700] block rounded-full;
}

.value span[aria-label='Pink'] span,
.list-unstyled span[aria-label='Pink'] span {
  @apply w-full h-full bg-[#FFC0CB] block rounded-full;
}

.value span[aria-label='Copper'] span,
.list-unstyled span[aria-label='Copper'] span {
  @apply w-full h-full bg-[#B87333] block rounded-full;
}

.value span[aria-label='Magenta'] span,
.list-unstyled span[aria-label='Magenta'] span {
  @apply w-full h-full bg-[#FF00FF] block rounded-full;
}

.value span[aria-label='Turquoise'] span,
.list-unstyled span[aria-label='Turquoise'] span {
  @apply w-full h-full bg-[#40E0D0] block rounded-full;
}

.value span[aria-label='Lime'] span,
.list-unstyled span[aria-label='Lime'] span {
  @apply w-full h-full bg-[#00FF00] block rounded-full;
}

.tb-btn.active {
  @apply py-2 text-white bg-primary px-7 rounded-tl-md rounded-tr-md;
}
.tb-btn {
  @apply py-2 bg-white px-7 text-gray;
}

.page_title:empty {
  @apply hidden;
}

label.preferred input:checked + .ico {
  @apply text-green;
}
label.preferred .content p,
label.remove .content p {
  @apply text-xs;
}

label.remove input:checked + .ico {
  @apply text-primary;
}

label.pickcheck .ico {
  @apply invisible opacity-0;
}
label.pickcheck input:checked + .ico {
  @apply visible opacity-100;
}

.value span[aria-label='#000000'] span,
.list-unstyled span[aria-label='#000000'] span {
  @apply block w-full h-full bg-black rounded-full;
}

.value span[aria-label='#FFFFFF'] span,
.list-unstyled span[aria-label='#FFFFFF'] span {
  @apply block w-full h-full bg-white rounded-full;
}

.value span[aria-label='#C0C0C0'] span,
.list-unstyled span[aria-label='#C0C0C0'] span {
  @apply w-full h-full bg-[#C0C0C0] block rounded-full;
}

.value span[aria-label='#808080'] span,
.list-unstyled span[aria-label='#808080'] span {
  @apply w-full h-full bg-[#808080] block rounded-full;
}

.value span[aria-label='#0000FF'] span,
.list-unstyled span[aria-label='#0000FF'] span {
  @apply w-full h-full bg-[#0000FF] block rounded-full;
}

.value span[aria-label='#FF0000'] span,
.list-unstyled span[aria-label='#FF0000'] span {
  @apply w-full h-full bg-[#FF0000] block rounded-full;
}

.value span[aria-label='#FFFF00'] span,
.list-unstyled span[aria-label='#FFFF00'] span {
  @apply w-full h-full bg-[#FFFF00] block rounded-full;
}

.value span[aria-label='#008000'] span,
.list-unstyled span[aria-label='#008000'] span {
  @apply w-full h-full bg-[#008000] block rounded-full;
}

.value span[aria-label='#800080'] span,
.list-unstyled span[aria-label='#800080'] span {
  @apply w-full h-full bg-[#800080] block rounded-full;
}

.value span[aria-label='#FFA500'] span,
.list-unstyled span[aria-label='#FFA500'] span {
  @apply w-full h-full bg-[#FFA500] block rounded-full;
}

.value span[aria-label='#F5F5DC'] span,
.list-unstyled span[aria-label='#F5F5DC'] span {
  @apply w-full h-full bg-[#F5F5DC] block rounded-full;
}

.value span[aria-label='#A52A2A'] span,
.list-unstyled span[aria-label='#A52A2A'] span {
  @apply w-full h-full bg-[#A52A2A] block rounded-full;
}

.value span[aria-label='#CD7F32'] span,
.list-unstyled span[aria-label='#CD7F32'] span {
  @apply w-full h-full bg-[#CD7F32] block rounded-full;
}

.value span[aria-label='#FFD700'] span,
.list-unstyled span[aria-label='#FFD700'] span {
  @apply w-full h-full bg-[#FFD700] block rounded-full;
}

.value span[aria-label='#FFC0CB'] span,
.list-unstyled span[aria-label='#FFC0CB'] span {
  @apply w-full h-full bg-[#FFC0CB] block rounded-full;
}

.value span[aria-label='#B87333'] span,
.list-unstyled span[aria-label='#B87333'] span {
  @apply w-full h-full bg-[#B87333] block rounded-full;
}

.value span[aria-label='#FF00FF'] span,
.list-unstyled span[aria-label='#FF00FF'] span {
  @apply w-full h-full bg-[#FF00FF] block rounded-full;
}

.value span[aria-label='#40E0D0'] span,
.list-unstyled span[aria-label='#40E0D0'] span {
  @apply w-full h-full bg-[#40E0D0] block rounded-full;
}

.value span[aria-label='#00FF00'] span,
.list-unstyled span[aria-label='#00FF00'] span {
  @apply w-full h-full bg-[#00FF00] block rounded-full;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100% !important;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: block;
}

.flatpickr-calendar .today {
  @apply text-white bg-gray border-gray;
}
.flatpickr-calendar .selected,
.flatpickr-calendar .selected:hover {
  @apply bg-primary border-primary;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  @apply !bottom-[0px];
}

.swiper-pagination-bullet {
  @apply !bg-primary;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  @apply text-xl font-extrabold !px-2 text-primary;
}

.vehicleImagesSwiper .swiper-button-prev {
  @apply bg-white  border-primary !h-fit !w-fit rounded-full !p-1;
}

.vehicleImagesSwiper .swiper-button-next {
  @apply bg-white  border-primary  !h-fit !w-fit rounded-full !p-1;
}
